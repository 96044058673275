// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    apiUrl: "https://vida-apis.theretailinsightsdemos.com/", //DEV
    // apiUrl: "https://vida-apis-nr.theretailinsightsdemos.com/", //DEV

    // apiUrl: "https://vida-apis-apm.theretailinsightsdemos.com/", //DEV Appeasement

    
    // testapiUrl: "https://gateway-edge.theretailinsightsdemos.com/", //testing
    // apiUrl: "https://dev-oms-api.vidagroup.com/", //preprod
    // apiUrl: "https://oms-api.vidagroup.com/", //prod
    // 
  
  
    magentoAPIURL: "http://magento-sahyadri.theretailinsightsdemos.com/", //DEV
  };
